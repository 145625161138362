import '../../../App.css';
const Home = () => {
    return (
        <div className="home">
            <br></br>
            <h1>Welcome to my website!</h1>
            <p>Take a look around!</p>
            <div id="news">
                <h1>News</h1>
                <h2>Quake Tournament</h2>
                <p>I am planning on having a quake tournament soon.</p>
                <p>If interested please look at the registration </p>
                <p>HERE</p>
            </div>
            
        </div>
    );
}
export default Home;